import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Thunk action for submitting order
export const orderSubmission = createAsyncThunk(
  "submitOrder/orderSubmit",
  async (orderDetails, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/social/order`,
        orderDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const submitOrderSlice = createSlice({
  name: "submitOrder",
  initialState: {
    orderDetails: {
      link: [],
      quantity: [],
      service_type: "",
      runs: "",
      interval: "",
      likeType: "",
      likesQuantity: "",
      speedOfViews: "",
      peopleCategory: "",
      numberOfDays: "",
      numberOfPost: "",
      engagementPackage: "",
      userToTag: "",
      comment: "",
    },
    loading: false,
    error: null,
  },
  reducers: {
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setOrderDetails } = submitOrderSlice.actions;
export default submitOrderSlice.reducer;
