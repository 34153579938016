import React, { useState, useEffect } from "react";
import Table from "../../../../components/shared/Table";
import UserEdit from "./UserEdit";
import UserOrders from "./UserOrders"; // Import the new component
import axios from "axios";
import { Modal } from "antd";

const AllUsers = () => {
  const [currentPage, setCurrentPage] = useState("userdetails-page");
  const [selectedUser, setSelectedUser] = useState(null);
  const [data, setData] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const token = localStorage.getItem("adminToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_IGCARTS_BASE_URL}/user/all-users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json; charset=utf-8",
            },
          }
        );
        const userData = response.data.data;
        const formattedData = userData.map((user) => ({
          username: user.user_name,
          email: user.email,
          ipaddress: user.ip_address,
          wallet: parseFloat(user.Wallet_amount_after).toFixed(2),
          lastdepositdate: formatTimestamp(user.last_deposit_date),
          price: JSON.parse(user.Price_price),
          referralcode: user.referral_code,
          totaldeposit: user.total_payments_amount,
        }));
        setData(formattedData);
      } catch (error) {
        alert(
          "An error occurred while fetching user details. Please try again!"
        );
      }
    };

    const formatTimestamp = (timestamp) => {
      return timestamp
        ?.replace("T", " ")
        .substring(0, timestamp.lastIndexOf("."));
    };

    fetchData();
  }, [token]);

  function handleMove(user) {
    setSelectedUser(user);
    setCurrentPage("edit-page");
  }

  function handleViewOrders(user) {
    setSelectedUser(user);
    setCurrentPage("orders-page");
  }

  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const handleRemove = async (user) => {
    setSelectedUser(user);
    showDeleteModal();
  };

  const deleteUserConfirmed = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/user/delete-user`,
        { user_name: selectedUser.username },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );
      if (response.status === 200) {
        setData((prevData) => prevData.filter((u) => u !== selectedUser));
        hideDeleteModal();
      } else {
        alert("Failed to delete user. Please try again later.");
      }
    } catch (error) {
      alert("Error deleting user, please try again later.");
    }
  };

  const userdetails = [
    {
      name: "Username",
      selector: (row) => row.username,
    },
    { name: "Email", selector: (row) => row.email },
    { name: "Ip Address", selector: (row) => row.ipaddress },
    { name: "Wallet", selector: (row) => row.wallet, width: "100px" },
    { name: "Last Deposit Date", selector: (row) => row.lastdepositdate },
    { name: "Referral Code", selector: (row) => row.referralcode },
    { name: "Tot. Dep.", selector: (row) => row.totaldeposit, width: "120px" },
    {
      name: "Edit",
      selector: (row) => (
        <button onClick={() => handleMove(row)} className="text-blue-700">
          Edit
        </button>
      ),
      width: "90px",
    },
    {
      name: "Recent Orders",
      selector: (row) => (
        <button
          onClick={() => handleViewOrders(row)}
          className="text-green-700"
        >
          View Orders
        </button>
      ),
    },
    {
      name: "Rem.",
      selector: (row) => (
        <button
          onClick={() => handleRemove(row)}
          className="text-red-700 italic"
        >
          Remove
        </button>
      ),
      width: "90px",
    },
  ];

  const handleSaveChanges = (updatedUser) => {
    setData((prevData) =>
      prevData.map((user) => (user === selectedUser ? updatedUser : user))
    );
    setCurrentPage("userdetails-page");
  };

  return (
    <>
      {currentPage === "userdetails-page" ? (
        <React.Fragment>
          <Table title="User Details" column={userdetails} data={data} />
        </React.Fragment>
      ) : currentPage === "edit-page" ? (
        <UserEdit
          user={selectedUser}
          onSaveChanges={handleSaveChanges}
          onCancel={() => setCurrentPage("userdetails-page")}
        />
      ) : (
        <UserOrders
          user={selectedUser}
          onCancel={() => setCurrentPage("userdetails-page")}
        />
      )}

      {/* Delete user Confirmation Modal */}
      <Modal
        title={<span style={{ color: "#f44336" }}>Confirm Deletion</span>}
        open={deleteModalVisible}
        onOk={deleteUserConfirmed}
        onCancel={hideDeleteModal}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{
          style: { backgroundColor: "#f44336", color: "#ffffff" },
        }}
        cancelButtonProps={{
          style: { backgroundColor: "#fc8a24", color: "#ffffff" },
        }}
      >
        <p className="font-semibold">
          Are you sure you want to delete this user?
        </p>
      </Modal>
    </>
  );
};

export default AllUsers;
