import React, { useEffect, useState } from "react";
import axios from "axios";
import AutoServicesTable from "./components/AutoServicesTable";
import AutoServicesForm from "./components/AutoServicesForm";
import DeleteModal from "./components/DeleteModal";
import AutoServicesConfig from "./components/AutoServicesConfig";
import EditNoOfPinnedPostModal from "./components/EditNoOfPinnedPostModal"; // New import for edit modal

const AutoServices = () => {
  const [autoServiceData, setAutoServiceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false); // State for edit modal visibility
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [autoServicePost, setAutoServicePost] = useState({
    username: "",
    service_name: "",
    social_username: "",
    duration: "",
    no_of_posts: "",
    quantity: "",
    no_of_post_pinned: "",
  });
  const [editNoOfPinnedPost, setEditNoOfPinnedPost] = useState({
    no_of_post_pinned: "",
  });

  const [autoServiceConfigData, setAutoServiceConfigData] = useState([]);
  const token = localStorage.getItem("adminToken");

  const fetchAutoServices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/auto-subscription/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );
      setAutoServiceData(response.data.data);
    } catch (error) {
      alert(
        "We encountered an error while fetching services, please try again"
      );
    }
  };

  const fetchAutoServiceConfigData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/auto-service/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );
      setAutoServiceConfigData(response.data.data);
    } catch (error) {
      alert(
        "We encountered an error while fetching services, please try again"
      );
    }
  };

  useEffect(() => {
    fetchAutoServices();
    fetchAutoServiceConfigData();
  }, []);

  const showDeleteModal = (id) => {
    setSelectedServiceId(id);
    setDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const handleRemove = (row) => {
    showDeleteModal(row.auto_subscription_id);
  };

  const showEditModal = (row) => {
    setSelectedServiceId(row.auto_subscription_id);
    setEditNoOfPinnedPost({ no_of_post_pinned: row.no_of_post_pinned });
    setEditModalVisible(true);
  };

  const hideEditModal = () => {
    setEditModalVisible(false);
  };

  const deleteServiceConfirmation = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/auto-subscription/delete/${selectedServiceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );
      if (response.status === 200) {
        setAutoServiceData((prevData) =>
          prevData.filter(
            (service) => service.auto_subscription_id !== selectedServiceId
          )
        );
        hideDeleteModal();
      } else {
        alert("Failed to delete service. Please try again later.");
      }
    } catch (error) {
      alert("Error deleting service, please try again later.");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAutoServicePost({ ...autoServicePost, [name]: value });
  };

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setEditNoOfPinnedPost({ ...editNoOfPinnedPost, [name]: value });
  };

  const handleDurationSelect = (value) => {
    setAutoServicePost({ ...autoServicePost, duration: value });
  };

  const handleServiceSelect = (serviceName) => {
    const selectedService = autoServiceConfigData.find(
      (service) => service.service_name === serviceName
    );
    if (selectedService) {
      setAutoServicePost({
        ...autoServicePost,
        service_name: selectedService.service_name,
      });
    }
  };

  const addAutoSubscriptionService = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/auto-subscription/create`,
        autoServicePost,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );

      setAutoServiceData([...autoServiceData, response.data]);
      setLoading(false);
      fetchAutoServices();
      setAutoServicePost({
        username: "",
        service_name: "",
        social_username: "",
        duration: "",
        no_of_posts: "",
        quantity: "",
      });
    } catch (error) {
      setLoading(false);
      alert("Error creating auto subscription service, please try again.");
      console.log("response error", error);
    }
  };

  const updateNoOfPinnedPost = async () => {
    try {
      setLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/auto-subscription/update/${selectedServiceId}`,
        editNoOfPinnedPost,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );

      if (response.status === 200) {
        setAutoServiceData((prevData) =>
          prevData.map((service) =>
            service.auto_subscription_id === selectedServiceId
              ? { ...service, ...editNoOfPinnedPost }
              : service
          )
        );
        hideEditModal();
      } else {
        alert("Failed to update service. Please try again later.");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Error updating service, please try again later.");
    }
  };

  return (
    <>
      <AutoServicesTable
        autoServiceData={autoServiceData}
        handleRemove={handleRemove}
        handleEdit={showEditModal} // Show edit modal on edit button click
      />
      <AutoServicesForm
        autoServicePost={autoServicePost}
        handleInputChange={handleInputChange}
        addAutoSubscriptionService={addAutoSubscriptionService}
        loading={loading}
        services={autoServiceConfigData}
        handleServiceSelect={handleServiceSelect}
        handleDurationSelect={handleDurationSelect}
      />
      <DeleteModal
        deleteModalVisible={deleteModalVisible}
        hideDeleteModal={hideDeleteModal}
        deleteServiceConfirmation={deleteServiceConfirmation}
      />
      <EditNoOfPinnedPostModal
        editModalVisible={editModalVisible}
        hideEditModal={hideEditModal}
        handleInputChange={handleEditInputChange}
        editNoOfPinnedPost={editNoOfPinnedPost}
        updateNoOfPinnedPost={updateNoOfPinnedPost}
        loading={loading}
      />
      <AutoServicesConfig
        autoServiceConfigData={autoServiceConfigData}
        setAutoServiceConfigData={setAutoServiceConfigData}
        fetchAutoServiceConfigData={fetchAutoServiceConfigData}
      />
    </>
  );
};

export default AutoServices;
