import React from "react";
import { Link } from "react-router-dom";
import { currentYear } from "../../utils";

const Footer = () => {
  return (
    <footer className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-4 bg-[#f49a39] text-white">
      <div className="p-2 flex xl:justify-between lg:justify-between md:flex-col sm:flex-col xs:flex-col items-center gap-2">
        <Link to="/" className="text-3xl font-semibold font-Montserrat">
          Igcarts
        </Link>
        <p className="font-FiraSans font-normal">
          Copyright &#169; {currentYear} Igcarts. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
