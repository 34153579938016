import React, { useEffect, useState } from "react";
import Table from "../../../../components/shared/Table";
import axios from "axios";

const UsersOrders = () => {
  const [userOrders, setUserOrders] = useState([]);
  const token = localStorage.getItem("adminToken");

  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_IGCARTS_BASE_URL}/social/get-all-orders`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json; charset=utf-8",
            },
          }
        );
        const userOrders = response.data.data;
        const formattedOrders = userOrders.map((user) => ({
          username: user.User_user_name || user["User.user_name"],
          servicetype: user.service_type,
          quantity: user.quantity,
          price: user.price,
          order: user.order,
        }));
        setUserOrders(formattedOrders);
      } catch (error) {
        alert("An error occured while fetching all user orders");
      }
    };
    fetchUserOrders();
  }, [token]);
  const userorders = [
    { name: "Username", selector: (row) => row.username },
    { name: "Service Type", selector: (row) => row.servicetype },
    { name: "Quantity", selector: (row) => row.quantity },
    { name: "Price ($)", selector: (row) => row.price },
    { name: "Order", selector: (row) => row.order },
  ];
  return (
    <div>
      <Table title="User Orders" column={userorders} data={userOrders} />
    </div>
  );
};

export default UsersOrders;
