import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import { Link, useOutletContext } from "react-router-dom";
import ThreeRowForm from "../../components/shared/ThreeRowForm";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const AutoLikes = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [data, setData] = useState({
    link: [],
    quantity: [],
    numberOfPost: "",
    likesQuantity: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { link, numberOfPost, likesQuantity } = data;
  const onChange = (e) => {
    let updated = {};
    if (e.target.name === "likesQuantity" || e.target.name === "numberOfPost") {
      updated = { [e.target.name]: e.target.value };
    } else {
      updated = { [e.target.name]: [e.target.value] };
    }
    setData({
      ...data,
      ...updated,
    });
    const value = { ...data, ...updated };
    const { numberOfPost, likesQuantity } = value;
    const total = numberOfPost * likesQuantity;
    setQuantity(total);
    setData((prevData) => ({
      ...prevData,
      quantity: [total],
    }));
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: [],
          quantity: [],
          numberOfPost: "",
          likesQuantity: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };

  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More High Quality Likes?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Auto Add High Quality Likes - None Drop
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg">
        <p className="text-sm sm:text-xs xs:text-xs text-[#303efb]">
          Choose number of future posts likes package
        </p>
        <p className="text-sm sm:text-xs xs:text-xs text-[#303efb]">
          The likes are instant for your future posts
        </p>
        <Link
          to="/auto_high_quality_none_drop_likes"
          className="text-sm sm:text-xs xs:text-xs font-semibold text-red-600"
        >
          Click here for auto high quality slow likes choose slow delivery
          option
        </Link>
      </div>
      <ThreeRowForm
        labelOne="Instagram Username"
        placeholderOne="Instagram Username"
        labelTwo="Number of Posts"
        labelThree="Auto Likes"
        onChangeOne={onChange}
        onClick={onChange}
        onChangeThree={onChange}
        valueOne={link}
        valueTwo={numberOfPost}
        valueThree={likesQuantity}
        nameOne="link"
        nameTwo="numberOfPost"
        nameThree="likesQuantity"
      />

      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
        <Button
          type="submit"
          title={loading ? "Adding likes..." : "Auto Add Likes"}
          variant="primary"
          size="sm"
          onClick={onSubmit}
          disabled={
            !isAuthenticated ||
            !link ||
            !numberOfPost ||
            !likesQuantity ||
            loading
          }
        />
        {/* Success Modal */}
        <SuccessModal
          visible={successModalVisible}
          onClose={() => setSuccessModalVisible(false)}
          message={message}
        />
        {/* Error Modal */}
        <ErrorModal
          visible={errorModalVisible}
          onClose={() => setErrorModalVisible(false)}
          message={message}
        />{" "}
      </div>
    </div>
  );
};

export default AutoLikes;
