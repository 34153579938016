import React, { useEffect } from "react";
import FormAndButton from "../../components/shared/FormAndButton";
import Button from "../../components/shared/Button";
import { Link, useOutletContext } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import {
  clearMedia,
  loadInstagramMedia,
} from "../../store/Slice/instagramServiceSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";
import { InstagramRealOrganicLikesReview } from "../../SEO/components/customerReviews";
import {
  instagramRealOrganicLikesSeoAccordion,
  instagramRealOrganicLikesSeoAccordionHeading,
} from "../../SEO/components/pagesAccordion";

const InstagramRealOrganicLikes = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { media } = useSelector((state) => state.instagramService);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [orderDetails, setOrderDetails] = useState({
    link: "",
    quantity: "",
    peopleCategory: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    return () => {
      dispatch(clearMedia());
    };
  }, [dispatch]);
  useEffect(() => {
    setQuantity(0);
    setAccordionData(instagramRealOrganicLikesSeoAccordion);
    setAccordionHeading(instagramRealOrganicLikesSeoAccordionHeading);
    setReviews(InstagramRealOrganicLikesReview);
  }, []);
  const onClick = () => {
    dispatch(loadInstagramMedia(username));
  };
  const { quantity, peopleCategory } = orderDetails;
  const onChange = (e) => {
    setUsername(e.target.value);
  };
  const peopleCategoryChange = (e) => {
    setOrderDetails({ ...orderDetails, peopleCategory: e.target.value });
  };

  const onQuantityChange = (index, quantities, link, quantity) => {
    let sumOfQuantities = 0;
    Object.keys(quantities).forEach((item) => {
      if (typeof quantities[item] == "number") {
        sumOfQuantities += quantities[item];
      }
    });
    setQuantity(sumOfQuantities);
    setOrderDetails({ ...orderDetails, link, quantity });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!media) {
      toast.info("Please load media first.");
      return;
    }
    if (quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(orderDetails));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        await dispatch(clearMedia());
        setSuccessModalVisible(true);
        setMessage(responseMessages.isAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setUsername("");
        setOrderDetails({
          link: "",
          quantity: "",
          service_type: window.location.pathname,
          peopleCategory: "",
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === '"link" is not allowed to be empty') {
          setMessage(responseMessages.linkClickMessage);
        } else if (response.payload.message === "Incorrect service ID") {
          setMessage(responseMessages.unavailableServiceDuetoId);
        } else if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };
  return (
    <div className="font-FiraSans py-20 px-14 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want Organic Instagram Likes for Authentic Engagement?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Buy Real Organic Instagram Likes
      </h3>
      <div className="flex mb-7 flex-col gap-4 p-3 bg-blue-200 text-blue-500 rounded-lg">
        <div>
          <p className="text-sm sm:text-xs xs:text-xs">
            Give your Instagram posts a genuine boost with our Real Organic
            Likes service. This unique offering provides 100% super real
            Instagram likes from active accounts, ensuring your engagement is as
            authentic as it gets. NO BOTS Involved.
          </p>
        </div>
        <div>
          <p className="text-sm sm:text-xs xs:text-xs mb-2">
            Why Should You Buy Real Instagram Organic Likes?
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - None of its kind in the market
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - 100% Super Real Instagram Likes from real active accounts
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - All manual Promotions!!! No bots.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - Choose your demographic
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - The minimum is 100; Maximum is 10000
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - Order only when your auto likes or other likes in the panel have
            been completely delivered.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - Live chat is available 24/7 for assistance.
          </p>
        </div>
      </div>

      <FormAndButton
        placeholder="Enter your Instagram Username"
        label="Instagram Username"
        onChange={onChange}
        value={username}
        onClick={onClick}
        mediaUrl={media}
        username={username}
        onQuantityChange={onQuantityChange} // Pass onQuantityChange callback
      />
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1">
        <label className="block">Female / Male / Europeans / American</label>
        <select
          id="peopleCategory"
          name="peopleCategory"
          value={peopleCategory}
          onChange={peopleCategoryChange}
          className="w-2/3 px-2 sm:w-full xs:w-full py-3 rounded-lg shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
        >
          <option value="" disabled selected>
            Select category
          </option>
          <option value="Mix">Mix</option>
          <option value="Female">Female</option>
          <option value="Male">Male</option>
          <option value="African American">Europeans / American</option>
        </select>
      </div>
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
        <Button
          type="submit"
          title={loading ? "Sending..." : "Add Likes"}
          variant="primary"
          size="sm"
          onClick={onSubmit}
          disabled={loading || !peopleCategory || !isAuthenticated}
        />
        <ToastContainer />
        {/* Success Modal */}
        <SuccessModal
          visible={successModalVisible}
          onClose={() => setSuccessModalVisible(false)}
          message={message}
        />
        {/* Error Modal */}
        <ErrorModal
          visible={errorModalVisible}
          onClose={() => setErrorModalVisible(false)}
          message={message}
        />
        {/* Info Modal */}
        <InfoModal
          visible={infoModalVisible}
          onClose={() => setInfoModalVisible(false)}
          message={message}
        />
      </div>
    </div>
  );
};

export default InstagramRealOrganicLikes;
