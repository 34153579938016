import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import FormAndButtonTwo from "../../components/shared/FormAndButtonTwo";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import {
  clearMedia,
  loadInstagramMedia,
} from "../../store/Slice/instagramServiceSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { ToastContainer, toast } from "react-toastify";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const CustomManualRealComm = () => {
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { media } = useSelector((state) => state.instagramService);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    link: [],
    comment: "",
    service_type: window.location.pathname,
    quantity: [],
  });
  useEffect(() => {
    return () => {
      dispatch(clearMedia());
    };
  }, [dispatch]);
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onClick = () => {
    dispatch(loadInstagramMedia(username));
  };
  const usernameOnChange = (e) => {
    setUsername(e.target.value);
  };
  const onChange = (e) => {
    const updated = { [e.target.name]: e.target.value };
    setOrderDetails({ ...orderDetails, ...updated });
    const value = { ...orderDetails, ...updated };
    const { comment } = value;
    setQuantity(comment / 10);
    setOrderDetails((prevData) => ({
      ...prevData,
      quantity: [comment],
    }));
  };

  const onMediaClick = (mediaLink) => {
    setOrderDetails({ ...orderDetails, link: [mediaLink] });
  };
  const { comment } = orderDetails;
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!media) {
      toast.info("Please load media first.");
      return;
    }
    if (comment < 10) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf10);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(orderDetails));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        await dispatch(clearMedia());
        setSuccessModalVisible(true);
        setMessage(responseMessages.isAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setOrderDetails({
          link: [],
          comment: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === '"link" is not allowed to be empty') {
          setMessage(responseMessages.linkClickMessage);
        } else if (response.payload.message === "Incorrect service ID") {
          setMessage(responseMessages.unavailableServiceDuetoId);
        } else if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };
  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Ig Custom Manual Comments?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Ig Custom Manual Comments
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg">
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Kindly enter your comments, 1 comments per line and please do not use
          @
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Minimum 5 comments, Dont add blank lines
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Most importantly kindly avoid sexist comments as instagram do not
          allow for such . If you add sexist comments by mistake, such comments
          will not be posted. You can also add Emojis.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs text-red-600">
          All comments are posted with US, English speaking accounts
        </p>
      </div>
      <form>
        <FormAndButtonTwo
          label="Instagram Username"
          placeholder="Enter instagram username"
          onChange={usernameOnChange}
          value={username}
          onClick={onClick}
          mediaUrl={media}
          username={username}
          onMediaClick={onMediaClick}
        />
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 mt-3">
          <label className="block">Package:</label>
          <select
            name="comment"
            onChange={onChange}
            value={comment}
            className="w-2/3 px-2 sm:w-full xs:w-full py-3 rounded-lg shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
          >
            <option>Select number of real comment</option>
            <option value="10">10 real Comments</option>
            <option value="15">15 real Comments</option>
            <option value="20">20 real Comments</option>
            <option value="25">25 real Comments</option>
            <option value="30">30 real Comments</option>
            <option value="40">40 real Comments</option>
            <option value="50">50 real Comments</option>
            <option value="60">60 real Comments</option>
            <option value="70">70 real Comments</option>
            <option value="80">80 real Comments</option>
            <option value="90">90 real Comments</option>
            <option value="100">100 real Comments</option>
          </select>
        </div>
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
          <Button
            type="submit"
            title={loading ? "Sending comments..." : "Send Comments"}
            variant="primary"
            size="sm"
            onClick={onSubmit}
            disabled={loading || !comment || !isAuthenticated}
          />
        </div>
      </form>
      <ToastContainer />
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
      {/* Info Modal */}
      <InfoModal
        visible={infoModalVisible}
        onClose={() => setInfoModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default CustomManualRealComm;
