import DataTable from "react-data-table-component";

const Table = ({ column, data, title = "Order History", ...props }) => {
  const customStyles = {
    table: {
      style: {
        borderRadius: "5px",
      },
    },
    header: {
      style: {
        minHeight: "56px",
        backgroundColor: "#FCBB45",
        color: "#001F68",
        textAlign: "center",
      },
    },
    head: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
    headCells: {
      style: {
        borderWidth: "1px",
        borderColor: "#ddd",
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
    cells: {
      style: {
        borderWidth: "1px",
        borderColor: "#ddd",
      },
    },
  };

  return (
    <DataTable
      customStyles={customStyles}
      columns={column}
      data={data}
      title={title}
      responsive={true}
      // {...props}
    />
  );
};

export default Table;
