import React from "react";

const index = () => {
  return (
    <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 font-FiraSans">
      <h3 className="pb-2 font-medium text-2xl text-center">
        Terms of Service
      </h3>
      <p>
        The use of services provided by IGCARTS and IGCARTS.com establishes
        agreement to these terms.
      </p>
      <p>
        By registering or using IGCARTS services you agree that you have read,
        fully understand and agree to be bound by these Terms of Service &
        Privacy Policy.
      </p>
      {/*General terms start*/}
      <div className="pt-3">
        <h3 className="font-bold text-lg">1 - General</h3>
        <p className="inline-flex gap-2 pl-6">
          1.
          <span>
            This is the most recent Terms of Service agreement as of Wednesday,
            31-January-2024 15:00:00 CEST
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          2.
          <span>
            IGCARTS, in its sole discretion, may modify or review these Terms of
            Service at any time without prior notice. All recent modifications
            or revisions done by IGCARTS replaces all former agreements and
            takes effect upon date of posting.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          3.
          <span>
            Continued use of the service following recent amendments signifies
            your assent to the revised Terms of Service. You further agree to
            apprise yourself of recent changes on the Terms of Service through
            periodic reviews of IGCARTS website.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          4.
          <span>
            IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS OF SERVICE OR DO NOT
            AGREE TO ANY OR ALL TERMS, DO NOT REGISTER.
          </span>
        </p>
      </div>
      {/*General terms ends*/}

      {/*Services terms start*/}
      <div className="pt-3">
        <h3 className="font-bold text-lg">2 - Service</h3>
        <p className="inline-flex gap-2 pl-6">
          1.
          <span>
            IGCARTS sole purpose is for the promotion of social media.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          2.
          <span>
            You shall not knowingly exploit the system including but not limited
            to, gaining advantage, exploit speed or any other bug in a manner
            other than purchasing them from the website or use the regular
            features.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          3.
          <span>
            If you change your username, password, email or any other mandatory
            field the script will stop automatically until you re-login and
            re-launch it. Should it happen, the time of script inactivity will
            not be added to your license.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          4.
          <span>
            Your may not turn your account to private, restrict to any country
            or apply any settings that may affect the regular delivery of the
            service.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          5.
          <span>
            We are not responsible for your actions and their consequences,
            including accounts or pages banned or photos removed, we will not
            issue any refund or compensation in this cases.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          6.
          <span>
            We are not responsible if your accounts is banned for any reason,
            please make sure you are responsible for any actions you initiate,
            IGCARTS is not affiliated with any of the social networks provided.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          7.
          <span>
            IGCARTS is brought to you to make your life easier with automated
            processes. We discourage spam and violation of the law, and in this
            cases we have the right to block or suspend your account.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          8.
          <span>
            It is your sole responsibility to comply with any social network you
            use and any legislation that you are subject to. You use IGCARTS at
            your own risk.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          9.
          <span>
            All the services provided on the marketplace have a default
            guarantee of 1 day, excluding special cases if insurance is included
            by the maximum period of 30 days from the date of the purchase.
          </span>
        </p>
      </div>
      {/*Services terms ends*/}

      {/*Purchase terms start*/}
      <div className="pt-3">
        <h3 className="font-bold text-lg">3 - Purchase</h3>
        <p className="inline-flex gap-2 pl-6">
          1.
          <span>
            You agree that upon purchasing you clearly understand and agree what
            you are purchasing and will not file a fraudulent dispute or
            charge-back.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          2.
          <span>
            Upon a fraudulent attempt to file a dispute, we grant the right, if
            necessary, to reset your account, terminate your account and/or
            permanently ban your IP address and all e-mail addresses associated
            with purchases made on the website.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          3.
          <span>
            The Credits or coins added in IGCARTS are not reversible if the
            system and web site it self are fully working. License system is
            counted based on the time you have registered your account,in this
            case if you do not login or use the account we will not replace the
            time and IGCARTS web site is not obligated in any case to refund
            you.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          4.
          <span>
            We are not able to transfer time or services between different
            services or tools.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          5.
          <span>
            The License is credited right after the purchase, its own
            responsability to use it even if you dont login into your account.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          6.
          <span>
            The users have the right to want their remain credits or coinz
            refunded to their Paypal,in this case the user is not able to use
            the web-site anymore.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          7.
          <span>
            NO REFUNDS are issued unless the the service is not delivered or is
            not working.
          </span>
        </p>
      </div>
      {/*Purchase terms ends*/}

      {/*Registration terms start*/}
      <div className="pt-3">
        <h3 className="font-bold text-lg">4 - Registration</h3>
        <p className="inline-flex gap-2 pl-6">
          1.
          <span>
            By registering you affirm that you are 18 years of age or an
            emancipated minor and are completely able and competent to enter
            into the terms and conditions set forth in these Terms of Service
            and comply and abide by them.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          2.
          <span>
            You may not use fake information while registering your account,
            including, fake name, fake e-mail, fake phone number or address. Any
            attempt to spoofing data will result in your account disabled.
          </span>
        </p>
      </div>
      {/*Registration terms ends*/}

      {/*Copyright terms start*/}
      <div className="pt-3">
        <h3 className="font-bold text-lg">5 - Copyright</h3>
        <p className="inline-flex gap-2 pl-6">
          1.
          <span>
            Without prior written consent of a IGCARTS representative, you may
            not copy or reproduce any texts, images or programming used on the
            IGCARTS website.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          2.
          <span>
            All brand icons are trademarks of their respective owners. The use
            of these trademarks does not indicate endorsement of the trademark
            holder by the owners, nor vice versa.
          </span>
        </p>
      </div>
      {/*Copyright terms ends*/}

      {/*Paypal & Credit Card Purchases terms start*/}
      <div className="pt-3">
        <h3 className="font-bold text-lg">
          6 - Paypal & Credit Card Purchases
        </h3>
        <p className="inline-flex gap-2 pl-6">
          1.
          <span>
            You agree that upon purchasing our services, that you clearly
            understand and agree what you are purchasing and will not file a
            fraudulent dispute via PayPal or any other payment processors.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          2.
          <span>
            Upon a fraudulent attempt to file a dispute, we receive the right,
            if necessary, to reset all followers and likes, terminate your
            account and/or permanently ban your IP address.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          3.
          <span>
            Since IGCARTS offers non-tangible irrevocable goods, you agree that
            after the purchase is made you cannot cancel/stop or remove any
            actions that this initiated. You understand that by Purchasing any
            goods on IGCARTS and IGCARTS.com this decision is final and you wont
            be able to reserve it.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          4.
          <span>
            No refunds or partial are made to Paypal, all credit added must be
            used in the site.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          5.
          <span>
            We reserve the right to deny Automatic Payments for any users until
            a manual verification is performed.
          </span>
        </p>
      </div>
      {/*Paypal & Credit Card Purchases terms ends*/}

      {/*Disclamer terms start*/}
      <div className="pt-3">
        <h3 className="font-bold text-lg">7 - Disclamer</h3>
        <p className="inline-flex gap-2 pl-6">
          1.
          <span>
            You agree that your use of IGCARTS is at your own risk. IGCARTS may
            not be held liable or accountable for any or whatever damages you or
            your business may incur.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          2.
          <span>
            IGCARTS does not guarantee website up-time or availability as it
            uses the internet to deliver its services.
          </span>
        </p>
        <p className="inline-flex gap-2 pl-6">
          3.
          <span>
            Since IGCARTS offers non-refundable goods, you agree that after the
            purchase is made you cannot cancel/stop or remove any actions that
            this initiated. You understand that by Purchasing any goods on
            IGCARTS and IGCARTS.com this decision is final and you wont be able
            to reserve it.
          </span>
        </p>
      </div>
      {/*Disclamer terms ends*/}

      {/*Change of terms start*/}
      <div className="pt-3">
        <h3 className="font-bold text-lg">5 - Copyright</h3>
        <p className="inline-flex gap-2 pl-6">
          1.
          <span>
            This Terms of Service are subject to change at any time. Notices of
            change will be considered given and effective on the date posted on
            our website. The changes made will become effective the date they
            are posted on our website. No further notice by IGCARTS is required
            upon your continued use of our website.
          </span>
        </p>
      </div>
      {/*Change of terms ends*/}
    </div>
  );
};

export default index;
