import React from "react";

const ThreeRowForm = ({
  labelOne,
  labelTwo,
  labelThree,
  placeholderOne,
  onChangeOne,
  onClick,
  onChangeThree,
  valueOne,
  valueTwo,
  valueThree,
  nameOne,
  nameTwo,
  nameThree,
}) => {
  return (
    <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-6">
      <div className="flex flex-col gap-8 lg:gap-6 md:gap-5 sm:gap-4 xs:gap-3">
        <div className="flex flex-col gap-1">
          <label className="font-FiraSans text-lg">{labelOne}</label>
          <input
            type="text"
            placeholder={placeholderOne}
            onChange={onChangeOne}
            value={valueOne}
            name={nameOne}
            className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="font-FiraSans text-lg">{labelTwo}</label>
          <select
            id="postsCount"
            onChange={onClick}
            value={valueTwo}
            name={nameTwo}
            className="w-full px-2 py-3 rounded-md shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
          >
            <option value="" disabled selected>
              Select the number of posts you want to be auto liked
            </option>
            {Array.from({ length: 50 }, (_, index) => index + 1).map(
              (count) => (
                <option key={count} value={count}>
                  {count} - ({count} post{count !== 1 ? "s" : ""})
                </option>
              )
            )}
          </select>
        </div>
        <div className="flex flex-col gap-1">
          <label className="font-FiraSans text-lg">{labelThree}</label>
          <select
            id="likes"
            onChange={onChangeThree}
            value={valueThree}
            name={nameThree}
            className="w-full px-2 py-3 rounded-md shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
          >
            <option value="" disabled selected>
              Select quantity of likes
            </option>
            <option value="100">100-120 Auto likes</option>
            <option value="200">200-230 Auto likes</option>
            <option value="300">300-330 Auto likes</option>
            <option value="500">500-550 Auto likes</option>
            <option value="600">600-660 Auto likes</option>
            <option value="700">700-770 Auto likes</option>
            <option value="800">800-880 Auto likes</option>
            <option value="900">900-990 Auto likes</option>
            <option value="1000">1000-1100 Auto likes</option>
            <option value="1200">1200-1300 Auto likes</option>
            <option value="1500">1500-1600 Auto likes</option>
            <option value="1750">1750-1900 Auto likes</option>
            <option value="2000">2000-2100 Auto likes</option>
            <option value="2500">2500-2700 Auto likes</option>
            <option value="3000">3000-3250 Auto likes</option>
            <option value="3500">3500-3750 Auto likes</option>
            <option value="4000">4000-4250 Auto likes</option>
            <option value="4500">4500-4800 Auto likes</option>
            <option value="5000">5000-5300 Auto likes</option>
            <option value="6000">6000-6500 Auto likes</option>
            <option value="7500">7500-8000 Auto likes</option>
            <option value="9000">9000-9500 Auto likes</option>
            <option value="10000">10000-10500 Auto likes</option>
            <option value="11000">11000-11200 Auto likes</option>
            <option value="12500">12500-12800 Auto likes</option>
            <option value="13500">13500-13800 Auto likes</option>
            <option value="15000">15000-15400 Auto likes</option>
            <option value="17500">17500-18000 Auto likes</option>
            <option value="20000">20000-20500 Auto likes</option>
            <option value="25000">25000-25500 Auto likes</option>
            <option value="30000">30000-30500 Auto likes</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default ThreeRowForm;
