import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "animate.css";

const Index = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [showFullContent, setShowFullContent] = useState({});

  const fetchBlogPosts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/blog/get-blog`,
        {
          headers: {
            "content-type": "application/json; charset=utf-8",
          },
        }
      );
      setBlogPosts(response.data.data);
      if (response.status !== 200) {
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const toggleContent = (index) => {
    setShowFullContent((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const renderBlogContent = (content, index) => {
    const anchorStyle = {
      color: "blue",
      textDecoration: "underline",
    };
    // Replace occurrences of <a> tags with styled <a> tags
    const styledContent = content.replace(
      /<a /g,
      `<a style="${Object.keys(anchorStyle)
        .map((key) => `${key}: ${anchorStyle[key]};`)
        .join(" ")}" `
    );
    return (
      <div className="flex flex-col items-center">
        <p
          className="font-FiraSans"
          dangerouslySetInnerHTML={{
            __html: showFullContent[index]
              ? styledContent
              : `${styledContent.slice(0, 500)}...`,
          }}
        />
        {showFullContent[index] ? (
          <button
            className="text-[#f49a39] border-[#f49a39] border p-1 rounded-xl cursor-pointer"
            onClick={() => toggleContent(index)}
          >
            Read Less
          </button>
        ) : (
          <button
            className="text-[#f49a39] border-[#f49a39] border p-1 rounded-xl cursor-pointer"
            onClick={() => toggleContent(index)}
          >
            Read More
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-2 pb-6 font-FiraSans">
      <h1 className="font-bold text-center text-4xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl">
        Blog Articles
      </h1>
      {blogPosts.length > 0 ? (
        blogPosts.map((blogPost, index) => (
          <div key={index} className="mt-4">
            <h1 className="font-Montserrat text-lg font-semibold text-center">
              {blogPost.heading}
            </h1>
            {renderBlogContent(blogPost.content, index)}
          </div>
        ))
      ) : (
        <div className="flex items-center py-10 justify-center">
          <p className="font-medium text-2xl mb-8 animate__animated animate__bounceInLeft animate__infinite animate__slower">
            There are no available blog posts at the moment...
          </p>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Index;
