import React, { useEffect } from "react";
import Button from "../../components/shared/Button";
import ThreeRowForm from "../../components/shared/ThreeRowForm";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";
import { AutoHighQualityLikesReview } from "../../SEO/components/customerReviews";
import {
  autoHighQualityLikesSeoAccordion,
  autoHighQualityLikesSeoAccordionHeading,
} from "../../SEO/components/pagesAccordion";

const AutoHighQualityLikes = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [data, setData] = useState({
    link: [],
    quantity: [],
    likesQuantity: "",
    numberOfPost: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { link, numberOfPost, likesQuantity } = data;
  const onChange = (e) => {
    let updated = {};
    if (e.target.name === "likesQuantity" || e.target.name === "numberOfPost") {
      updated = { [e.target.name]: e.target.value };
    } else {
      updated = { [e.target.name]: [e.target.value] };
    }
    setData((prevData) => ({
      ...prevData,
      ...updated,
    }));
    const value = { ...data, ...updated };
    const { numberOfPost, likesQuantity } = value;
    const total = numberOfPost * likesQuantity;
    setQuantity(total);
    setData((prevData) => ({
      ...prevData,
      quantity: [total],
    }));
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData(autoHighQualityLikesSeoAccordion);
    setAccordionHeading(autoHighQualityLikesSeoAccordionHeading);
    setReviews(AutoHighQualityLikesReview);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: "",
          quantity: "",
          numberOfPost: "",
          likesQuantity: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock2);
    }
  };

  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <h1 className="font-bold text-2xl text-center sm:text-lg xs:text-lg pb-5">
        High-Quality Auto Instagram Likes
      </h1>
      <h2 className="font-medium pb-9 text-3xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Buy Instagram Likes for Instant Impact on Your Future Posts!
      </h2>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg">
        <p className="text-sm sm:text-xs xs:text-xs text-[#303efb]">
          Boost your Instagram engagement effortlessly with our high-quality
          Instagram likes service. Gain instant likes for your future posts by
          choosing a package that suits your specific needs. Our service is
          designed to simplify your social media strategy, providing likes from
          real, active Instagram users as soon as you post.
        </p>
        <div className="bg-blue-200 text-blue-500 rounded-lg mt-3">
          <h2 className="text-sm sm:text-xs xs:text-xs text-[#303efb]">
            Instant IG Likes for Future Posts
          </h2>
          <p className="text-sm sm:text-xs xs:text-xs text-[#303efb] mt-1 pl-2">
            - Choose the number of future posts likes package.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs text-[#303efb] pl-2">
            - The likes are instant for your future IG posts.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs text-[#303efb] pl-2">
            - Perfect for new profiles or trial runs, get likes for up to 5
            future posts.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs text-[#303efb] pl-2">
            - Ideal for maintaining consistent engagement.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs text-[#303efb] pl-2">
            - Designed for influencers and businesses looking for maximum
            impact.
          </p>
        </div>
      </div>
      <ThreeRowForm
        labelOne="Instagram Username"
        labelTwo="Number of Posts"
        labelThree="Auto Real Likes"
        placeholderOne="Instagram Username"
        onChangeOne={onChange}
        onClick={onChange}
        onChangeThree={onChange}
        valueOne={link}
        valueTwo={numberOfPost}
        valueThree={likesQuantity}
        nameOne="link"
        nameTwo="numberOfPost"
        nameThree="likesQuantity"
      />
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
        <Button
          type="submit"
          title={loading ? "Sending likes..." : "Send Likes"}
          variant="primary"
          size="sm"
          onClick={onSubmit}
          disabled={
            !isAuthenticated ||
            !link ||
            !numberOfPost ||
            !likesQuantity ||
            loading
          }
        />
      </div>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default AutoHighQualityLikes;
