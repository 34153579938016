import React from "react";
import { homepageMedias, hompageCardRendering } from "../../utils";
import Card from "./components/Card";
import { Link } from "react-router-dom";
import { IoCaretForwardCircleSharp } from "react-icons/io5";
import { useSelector } from "react-redux";

const Index = () => {
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const displayUser = useSelector((state) => state.login.user);

  return (
    <div className="pt-4 lg:pt-6 md:pt-5 sm:pt-4 xs:pt-3">
      <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 text-center flex flex-col gap-4">
        <p className="font-FiraSans text-2xl">
          Hello{" "}
          {isAuthenticated ? (
            <span>{displayUser?.user_name.toUpperCase()}</span>
          ) : (
            ""
          )}
          , we are
        </p>
        <h1 className="font-bold font-FiraSans text-5xl">Igcarts</h1>
        <p className="font-FiraSans text-lg">
          Igcarts is a powerful Instagram, Tiktok, Facebook, Youtube, Spotify
          Growth Service, grow your accounts, pages, and videos, all day, every
          day in a simple way
        </p>
      </div>
      {/*Authentication div*/}
      <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 flex flex-col items-center text-white font-FiraSans gap-12 py-4">
        <Link to="/signup">
          <p className="px-8 py-3 rounded-xl inline-flex gap-3 items-center bg-gradient-to-r from-[#f49a39] via-yellow-400 to-[#fc8a24] text-3xl">
            Sign up
            <IoCaretForwardCircleSharp />
          </p>
        </Link>
        <Link to="/signin">
          <p className="px-9 py-3 rounded-xl inline-flex gap-4 items-center bg-gradient-to-r from-[#f49a39] via-yellow-400 to-[#fc8a24] text-3xl">
            Sign in
            <IoCaretForwardCircleSharp />
          </p>
        </Link>
      </div>
      {/*Why choose us div*/}
      <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 flex flex-col gap-4 py-12 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100">
        <h6 className="text-center font-bold font-FiraSans text-2xl sm:text-xl xs:text-xl md:text-xl">
          Why Choose Us For Your Social Media Growth?
        </h6>
        <div className="flex justify-center xl:gap-8 font-FiraSans lg:gap-4 md:flex-wrap sm:flex-col xs:flex-col items-center gap-3">
          <div className="shadow-2xl flex flex-col gap-2 rounded-md bg-white p-4 size-60">
            <p className="font-semibold text-lg">Proven Track Record</p>
            <span className="font-normal text-base">
              Join thousands of satisfied customers who've seen real social
              media growth.
            </span>
          </div>
          <div className="shadow-2xl flex flex-col gap-2 rounded-md bg-white p-4 size-60">
            <p className="font-semibold text-lg">24/7 Support</p>
            <span className="font-normal text-base">
              Our dedicated team is here to assist you anytime, ensuring a
              seamless experience.
            </span>
          </div>
          <div className="shadow-2xl flex flex-col gap-2 rounded-md bg-white p-4 size-60">
            <p className="font-semibold text-lg">Competitive Pricing</p>
            <span className="font-normal text-base">
              Get premium services without breaking the bank. We offer plans
              suitable for all budgets.
            </span>
          </div>
        </div>
      </div>

      <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 flex gap-3 py-5 lg:grid lg:grid-cols-3 lg:gap-y-4 lg:justify-items-center md:flex-wrap md:justify-center sm:flex-col sm:gap-3 xs:flex-col xs:gap-3 sm:items-center xs:items-center">
        {hompageCardRendering.map((item, i) => {
          return (
            <Card
              key={i}
              bgColor={item.bgColor}
              heading={item.heading}
              description={item.description}
              image={item.image}
            />
          );
        })}
      </div>
      {/*Get started div*/}
      <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 flex flex-col gap-4 py-10 bg-gradient-to-r from-gray-50 via-gray-100 to-gray-50 shadow-md">
        <h6 className="text-center font-bold font-FiraSans text-2xl sm:text-xl xs:text-xl md:text-xl">
          Get Started With The Most Reliable Social Media Growth Service
        </h6>
        <p className="font-FiraSans text-lg text-center">
          Experience exceptional growth across Instagram, Facebook, TikTok,
          YouTube, Spotify, and SoundCloud with our customized services designed
          to boost your online presence. Whether you're aiming to increase
          visibility, grow your follower base, or engage more effectively with
          your audience on multiple platforms, we're here to support your
          journey every step of the way.
        </p>
      </div>

      {/*Our service offering div*/}
      <div className="bg-[#140f0a] my-4">
        <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 font-FiraSans text-white text-center py-12 lg:py-11 md:py-10 sm:py-9 xs:py-9">
          <p className="text-xl sm:text-lg xs:text-lg">Our Service Offering</p>
          <h3 className="font-bold text-4xl lg:text-3xl md:text-2xl sm:text-xl xs:text-xl py-10">
            We Support Most Popular Social Networks
          </h3>
          <div className="flex gap-5 justify-center sm:flex-wrap sm:gap-3 xs:flex-wrap xs:gap-3">
            {homepageMedias.map((icon, index) => {
              return (
                <a key={index} href="###">
                  <img
                    src={icon}
                    alt="Top social media sopanels works with"
                    className="h-24 w-24"
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
