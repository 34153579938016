import React, { useState } from "react";
import Button from "../../../../components/shared/Button";
import axios from "axios";

const UserEdit = ({ user, onSaveChanges, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [editedUser, setEditedUser] = useState({ ...user });
  const token = localStorage.getItem("adminToken");
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedUser({ ...editedUser, [name]: value });
  };

  const handlePriceChange = (serviceName, newPrice) => {
    const updatedPrices = { ...editedUser.price, [serviceName]: newPrice };
    setEditedUser({ ...editedUser, price: updatedPrices });
  };

  const handleSaveChanges = async () => {
    try {
      setLoading(true);
      await axios.patch(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/user/update-user`,
        {
          user_name: editedUser.username,
          email: editedUser.email,
          wallet_balance: parseFloat(editedUser.wallet).toFixed(2),
          price: JSON.stringify(editedUser.price),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );
      onSaveChanges(editedUser);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Failed to update users. Please try again");
    }
  };

  return (
    <div className="flex justify-center items-center pt-4 shadow-md p-4">
      <div>
        <h2 className="text-center font-bold font-FiraSans text-xl">
          Edit User
        </h2>
        <div className="flex gap-2 items-center">
          <label htmlFor="username" className="text-lg font-semibold">
            Username:
          </label>
          <input
            type="text"
            id="username"
            name="username"
            value={editedUser.username}
            onChange={handleInputChange}
            className="outline-0 border border-gray-500 rounded-md p-2"
          />
        </div>
        <div className="flex gap-2 items-center py-3">
          <label htmlFor="email" className="text-lg font-semibold">
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={editedUser.email}
            onChange={handleInputChange}
            className="outline-0 border border-gray-500 rounded-md p-2"
          />
        </div>
        <div className="flex gap-2 items-center">
          <label htmlFor="username" className="text-lg font-semibold">
            Balance:
          </label>
          <input
            type="text"
            id="wallet"
            name="wallet"
            value={editedUser.wallet}
            onChange={handleInputChange}
            className="outline-0 border border-gray-500 rounded-md p-2"
          />
        </div>
        <div>
          <h3 className="text-center text-lg py-2 font-bold font-FiraSans">
            Service Prices
          </h3>
          {editedUser.price &&
            Object.entries(editedUser.price).map(([serviceName, price]) => (
              <div key={serviceName} className="flex gap-2 items-center pb-2">
                <span className="text-lg font-semibold">{serviceName}:</span>
                <input
                  type="text"
                  value={price}
                  onChange={(e) =>
                    handlePriceChange(serviceName, e.target.value)
                  }
                  className="outline-0 border border-gray-500 rounded-md p-2"
                />
              </div>
            ))}
        </div>
        <div className="flex gap-3 justify-center pt-5">
          <Button
            title={loading ? "Updating user" : "Save Changes"}
            onClick={handleSaveChanges}
            disabled={loading}
          />
          <Button title="Cancel" onClick={onCancel} />
        </div>
      </div>
    </div>
  );
};

export default UserEdit;
