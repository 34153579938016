import React, { useEffect } from "react";
import FormAndButton from "../../components/shared/FormAndButton";
import Button from "../../components/shared/Button";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMedia,
  loadInstagramMedia,
} from "../../store/Slice/instagramServiceSlice";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { high_quality_fast_likesSeoAccordion } from "../../SEO/components/pagesAccordion";
import { useAccordionContext } from "../../SEO/AccordionContext";

const HighQualityLikes = () => {
  const dispatch = useDispatch();
  const { media } = useSelector((state) => state.instagramService);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [orderDetails, setOrderDetails] = useState({
    link: "",
    quantity: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const onClick = () => {
    dispatch(loadInstagramMedia(username));
  };

  const onChange = (e) => {
    setUsername(e.target.value);
  };

  const { quantity } = orderDetails;

  const onQuantityChange = (index, quantities, link, quantity) => {
    let sumOfQuantities = 0;
    Object.keys(quantities).forEach((item) => {
      if (typeof quantities[item] == "number") {
        sumOfQuantities += quantities[item];
      }
    });
    setQuantity(sumOfQuantities);
    setOrderDetails({ ...orderDetails, link, quantity });
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  useEffect(() => {
    return () => {
      dispatch(clearMedia());
    };
  }, [dispatch]);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!media) {
      toast.info("Please load media first.");
      return;
    }
    if (quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(orderDetails));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        await dispatch(clearMedia());
        setSuccessModalVisible(true);
        setMessage(responseMessages.isAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setUsername("");
        setOrderDetails({
          link: "",
          quantity: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === '"link" is not allowed to be empty') {
          setMessage(responseMessages.linkClickMessage);
        } else if (response.payload.message === "Incorrect service ID") {
          setMessage(responseMessages.unavailableServiceDuetoId);
        } else if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };
  return (
    <div className="font-FiraSans py-20 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Instagram HQ Likes?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add High Quality IG Likes - None Drop
      </h3>
      <FormAndButton
        placeholder="Enter your Instagram Username"
        label="Instagram Username"
        onChange={onChange}
        value={username}
        onClick={onClick}
        mediaUrl={media}
        username={username}
        onQuantityChange={onQuantityChange} // Pass onQuantityChange callback
      />
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
        <Button
          type="submit"
          title={loading ? "Sending..." : "Add Likes"}
          variant="primary"
          size="sm"
          onClick={onSubmit}
          disabled={loading || !isAuthenticated}
        />
        <ToastContainer />
        {/* Success Modal */}
        <SuccessModal
          visible={successModalVisible}
          onClose={() => setSuccessModalVisible(false)}
          message={message}
        />
        {/* Error Modal */}
        <ErrorModal
          visible={errorModalVisible}
          onClose={() => setErrorModalVisible(false)}
          message={message}
        />
        {/* Info Modal */}
        <InfoModal
          visible={infoModalVisible}
          onClose={() => setInfoModalVisible(false)}
          message={message}
        />
      </div>
    </div>
  );
};

export default HighQualityLikes;
